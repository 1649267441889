import { UserInfo } from 'Components/Ui/UserInfo/UserInfo';
import React from 'react';
import './Dashboard.css';


export const Dashboard = () => {
  return (
    <div className='dashborard scroll'>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
      <UserInfo/>
    </div>
  )
}
